<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="2">
          <div class="input-group">
            <b-input :placeholder="$t('Search')" v-model="filters.search"></b-input>
          </div>
        </b-col>
        <b-col md="2">
          <v-select :placeholder="$t('Status')" :options="statuses" label="name" v-model="status" name="status"></v-select>
        </b-col>
        <b-col md="1">
          <b-button variant="success" @click="getSearch">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-col>
        <b-col></b-col>
        <b-col md="1">
          <div class="w-100 d-flex justify-content-end mb-1">
            <b-button variant="success" @click="generateExcel" class="mr-1">Excel</b-button>
            <b-button variant="primary" @click="handleAdd">{{$t('Add')}}</b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <div class="w-100">
        <b-card>
          <b-table
              striped
              hover
              :items="datas"
              :fields="fields"
              responsive=""
          >
            <template #cell(id)="data">
              <span class="text-md text-primary"> {{ (data.index +1) }}</span>
            </template>
            <template #cell(ministry_name)="{item}">
              <div v-if="item.active_history">
                <span v-if="item.active_history.management">
                {{item.active_history.management_type.name}} <br> {{item.active_history.management.name}}, <br>
                <span v-if="item.active_history.management.user">
                  <strong>{{item.active_history.management.user.lastname}} {{item.active_history.management.user.firstname}}</strong>
                </span>
              </span>
                        <span v-else>
                {{item.active_history.ministry_name}}
              </span>
              </div>
            </template>
            <template #cell(deadline)="{item}">
              <span v-if="item.active_history" class="d-flex">
                <span>
                  {{ item.active_history.deadline | dateFormat }}
                </span>
                <span class="ml-1"><b-icon icon="calendar2-week"></b-icon></span>
              </span>
            </template>
            <template #cell(status)="{item}">
              <div v-if="item.active_history">
                <strong>
                  {{item.active_history ? item.active_history.status.name : ''}}
                </strong>
              </div>
            </template>
            <template #cell(author)="{item}">
              <span v-if="item.active_history">
                {{item.active_history.author.firstname}} {{item.active_history.author.lastname}}
              </span>
            </template>
            <template #cell(updated_date)="{item}">
              <span v-if="item.active_history" class="d-flex">
                <span>
                  {{ item.active_history.updated_at | dateFormat }}
                </span>
                <span class="ml-1"><b-icon icon="calendar2-week"></b-icon></span>
              </span>
            </template>
            <template #cell(action)="data">
              <div class="d-flex">
                <b-button size="sm" variant="primary" @click="handlePageChange(data.item)" class="mr-1">
                  <b-icon icon="eye"></b-icon>
                </b-button>
                <b-button
                    size="sm"
                    variant="warning"
                    @click="handleEdit(data.item.id)"
                >
                  <b-icon icon="pencil" />
                </b-button>
                <b-button
                    class="ml-1"
                    size="sm"
                    variant="danger"
                    @click="handleDelete(data.item.id)"
                >
                  <b-icon icon="trash" />
                </b-button>
                <a v-if="data.item.active_history.file_path" target="_blank" class="ml-1" :href="`${backendPath.backendPath}${data.item.active_history.file_path}`">
                  <b-button size="sm" variant="info">
                    <b-icon icon="download"></b-icon>
                  </b-button>
                </a>
              </div>
            </template>
          </b-table>
        </b-card>
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            v-model="modalVisible"
            :title="modalTitle"
            hide-footer
            size="xl"
            scrollable
        >
          <ParagraphForm :id="id" @saved="fetchList" @closeModal="closeModal" />
        </b-modal>
      </div>
    </b-row>
  </div>
</template>

<script>
import { deleteParagraph, getCategories, getParagraphs } from '@/api/documents'
import { listTemplate } from '@/utils/mixins/listTemplate'
// eslint-disable-next-line import/extensions
import ParagraphForm from '@/views/loginpages/moderator/documents/ParagraphForm'
import vSelect from 'vue-select'
import {generateParagraphExcel, getStatuses, getYears} from '@/api/directories'
import backendPath from '../../../../libs/vue-axios.config'

const actions = {
  get: getParagraphs,
  delete: deleteParagraph,
}

export default {
  name: 'Paragraph',
  components: { ParagraphForm, vSelect },
  mixins: [
    listTemplate,
  ],
  data() {
    return {
      actions,
      statuses: [],
      backendPath,
      filters: {
        search: '',
        status_id: null,
        document_id: this.$route.params.id,
      },
      category: null,
      year: null,
      status: null,
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'paragraph',
          label: this.$t('Paragraph'),
        },
        {
          key: 'ministry_name',
          label: this.$t('Ministry_name'),
        },
        {
          key: 'deadline',
          label: this.$t('Deadline'),
        },
        {
          key: 'status',
          label: this.$t('Status_id'),
        },
        {
          key: 'author',
          label: this.$t('Author'),
        },
        {
          key: 'updated_date',
          label: this.$t('Updated_date'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
    }
  },
  mounted() {
    this.fetchStatuses()
  },
  methods: {
    closeModal() {
      this.modalVisible = false;
    },
    handlePageChange(data) {
      this.$router.push({name: 'paragraphHistory',
        params: { id: this.$route.params.id, paragraphId: data.paragraph_id ? data.paragraph_id : data.id },
      })
    },
    fetchStatuses() {
      getStatuses().then(res => {
        this.statuses = res.data.data;
      })
    },
    getSearch() {
      if(this.category) {
        this.filters.category_id = this.category.id;
      }else {
        this.filters.category_id = null
      }
      if(this.year) {
        this.filters.year_id = this.year.id;
      }else {
        this.filters.year_id = null
      }
      if(this.status) {
        this.filters.status_id = this.status.id
      }else {
        this.filters.status_id = null
      }
      this.pagination.page = 1;
      this.fetchList()
    },
    generateExcel() {
      this.showLoading = true;
      generateParagraphExcel({document_id:this.$route.params.id}).then(({data}) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'paragraph.xlsx');
        document.body.appendChild(link);
        link.click()
        this.showLoading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>
