<template>
  <div class="pb-2 pt-1">
    <div>
      <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
      <validation-observer ref="observer" class="w-100">
        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('Number')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Category')"
                  rules="required"
              >
              <b-form-input v-model="form.number" size="lg"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
              <b-form-group
                  :label="$t('ParagraphUz')"
                  label-for="name-input"
                  invalid-feedback="Name is required"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('ParagraphUz')"
                    rules="required"
                >
                <b-form-input
                    id="name-input"
                    name="paragraph_uz"
                    v-model="form.paragraph_uz"
                    required
                    size="lg"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                  :label="$t('ParagraphRu')"
                  label-for="name-input"
                  invalid-feedback="Name is required"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('ParagraphRu')"
                    rules="required"
                >
                <b-form-input
                    id="name-input"
                    name="paragraph_ru"
                    v-model="form.paragraph_ru"
                    required
                    size="lg"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                  :label="$t('ParagraphEn')"
                  label-for="name-input"
                  invalid-feedback="Name is required"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('ParagraphEn')"
                    rules="required"
                >
                <b-form-input
                    id="name-input"
                    name="paragraph_en"
                    v-model="form.paragraph_en"
                    required
                    size="lg"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-col>
          <b-col v-if="!id" md="6">
            <h6>{{$t('Action')}}</h6>
            <b-form-group
                v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                  v-for="(val, index) in statuses"
                  :key="index"
                  v-model="form.status_id"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  :value="val.id"
                  class="mt-1"
              >
                {{val.name}}
              </b-form-radio>
            </b-form-group>
            <div class="mt-2">
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('File')">
                      <b-form-file
                          id="file-default"
                          v-model="form.file_path"
                          value="file"
                          placeholder=""
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('Deadline')">
<!--                      <b-form-datepicker-->
<!--                          v-model="form.deadline"-->
<!--                          name="notification_date"-->
<!--                          locale="en"-->
<!--                      />-->
                      <input type="date" class="form-control" v-model="form.deadline" name="notification_date">
                    </b-form-group>
                  </b-col>
                </b-row>
            </div>
            <div>
              <b-form-group class="mt-1" :label="$t('Notification')">
                <b-row>
                  <b-col :md="6">
<!--                    <b-form-datepicker-->
<!--                        v-model="form.notification_date"-->
<!--                        name="notification_date"-->
<!--                        locale="en"-->
<!--                    />-->
                    <input type="date" class="form-control"  onfocus="this.min=new Date().toISOString().split('T')[0]" v-model="form.notification_date" name="notification_date">
                  </b-col>
                  <b-col :md="6">
                    <b-form-timepicker
                        id="timepicker-placeholder"
                        name="notification_time"
                        v-model="form.notification_time"
                        :placeholder="$t('ChooseTime')"
                        locale="ru"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="!id">
          <b-form-group
              v-slot="{ ariaDescribedby }"
          >
            <b-form-radio
                v-model="isMno"
                :aria-describedby="ariaDescribedby"
                name="radio"
                :value="true"
                @change="handleIsMnochange"
            >
              {{$t('Mno_center')}}
            </b-form-radio>
            <b-form-radio
                v-model="isMno"
                :aria-describedby="ariaDescribedby"
                name="radio"
                :value="false"
                class="mt-1"
                @change="handleIsMnochange"
            >
              {{$t('Another_statement')}}
            </b-form-radio>
          </b-form-group>
        </b-row>
       <div v-if="!id">
         <div
             v-if="isMno"
             class="mt-2"
         >
<!--           <h5>{{$t('Management')}}</h5>-->
           <b-form-group :label="$t('ChooseManagementType')">
             <b-form-select
                 v-model="form.management_type_id"
                 :options="management_type"
                 text-field="name"
                 value-field="id"
                 class="mt-1"
                 name="management_type"
                 @input="getManagementCategory"
             />
           </b-form-group>
           <b-form-group :label="$t('ChooseRank')">
             <b-form-select
                 v-model="form.management_id"
                 :options="management_category"
                 text-field="name"
                 value-field="id"
                 class="mt-2"
                 name="management_category"
                 @input="getManagementUser"
             />
           </b-form-group>
           <b-input v-model="fio" class="mt-2" disabled></b-input>
         </div>
         <div v-else>
           <b-form-group
               :label="$t('Ministry_name')"
               label-for="name-input"
               invalid-feedback="Name is required"
           >
             <b-form-input
                 id="name-input"
                 name="ministry_name"
                 v-model="form.ministry_name"
                 required
                 size="lg"
             />
           </b-form-group>
         </div>
       </div>
        <div class="mt-2">
          <b-container fluid>
            <div class="text-right mt-3">
              <b-button
                  variant="danger"
                  class="mr-2"
                  @click="$emit('closeModal')"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                  variant="success"
                  @click="saveSubmit"
              >
                {{$t('Add')}}
              </b-button>
            </div>
          </b-container>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  addParagraphs, editParagraph, getParagraph,
} from '@/api/documents'
import { getManagement, getManagementTypes, getStatuses } from '@/api/directories'

import { formTemplate } from '@/utils/mixins/formTemplate'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'

function initForm(data) {
  const get = (key, value = null) => _.get(data, key, value)

  return {
    id: get('id', null),
    paragraph_uz: get('paragraph_uz',null),
    number: get('number', null),
    paragraph_ru: get('paragraph_ru',null),
    paragraph_en: get('paragraph_en',null),
    status_id: get('status_id',null),
    file_path: get('file_path', null),
    notification_date: get('notification_date', null),
    notification_time: get('notification_time', null),
    executer_mno: get('executer_mno', null),
    management_type_id: get('management_type_id', null),
    management_id: get('management_id', null),
    ministry_name: get('ministry_name', null),
    document_id: get('document_id', null),
    deadline: get('deadline', null),
  }
}

const actions = {
  add: addParagraphs,
  update: editParagraph,
  show: getParagraph,
};

export default {
  name: 'ParagraphForm',
  mixins: [formTemplate],
  components: {
    ValidationProvider,
    ValidationObserver,
    moment,
  },
  data() {
    return {
      actions,
      form: initForm(),
      management_type: [],
      management_category: [],
      isMno: true,
      fio: '',
      statuses: [],
    }
  },
  mounted() {
    getManagementTypes().then(res => {
      this.management_type = res.data.data
    });
    getStatuses().then(res => {
      this.statuses = res.data.data;
    })
  },
  methods: {
    reformatData() {
      this.form.document_id = this.$route.params.id
      this.form.executer_mno = this.isMno
      if (this.$route.params.paragraphId) {
        this.form.paragraph_id = this.$route.params.paragraphId
      }
      const form = this.prepareFormData(this.form)
      return form
    },
    setForm(data) {
      this.form = initForm(data);
    },
    fetchManagement() {
      getManagement().then(res => {
        this.managements = res.data.data;
      })
    },
    getManagementCategory() {
      getManagement({ type_id: this.form.management_type_id, pageSize: 30 }).then(res => {
        this.management_category = res.data.data.data
      })
    },
    getManagementUser() {
      const user = this.management_category.filter(item => item.id === this.form.management_id)[0].user
      this.fio = `${user.lastname} ${user.firstname}`
      this.form.executer_mno = user.id
    },
    handleIsMnochange() {
      this.form.management_type_id = null;
      this.form.management_id = null;
      this.fio = '';
      this.form.executer_mno = null;
      this.form.ministry_name = null;
    },
    getMinimumDate() {
      console.log(moment().format('YYYY-MM-DD'))
      return moment().format('YYYY-MM-DD')
    }
  },
}
</script>

<style scoped>

</style>
