var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('div',{staticClass:"input-group"},[_c('b-input',{attrs:{"placeholder":_vm.$t('Search')},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)]),_c('b-col',{attrs:{"md":"2"}},[_c('v-select',{attrs:{"placeholder":_vm.$t('Status'),"options":_vm.statuses,"label":"name","name":"status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('b-col',{attrs:{"md":"1"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.getSearch}},[_c('b-icon',{attrs:{"icon":"search"}})],1)],1),_c('b-col'),_c('b-col',{attrs:{"md":"1"}},[_c('div',{staticClass:"w-100 d-flex justify-content-end mb-1"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"success"},on:{"click":_vm.generateExcel}},[_vm._v("Excel")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.handleAdd}},[_vm._v(_vm._s(_vm.$t('Add')))])],1)])],1)],1),_c('b-row',[_c('div',{staticClass:"w-100"},[_c('b-card',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.datas,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('span',{staticClass:"text-md text-primary"},[_vm._v(" "+_vm._s((data.index +1)))])]}},{key:"cell(ministry_name)",fn:function(ref){
var item = ref.item;
return [(item.active_history)?_c('div',[(item.active_history.management)?_c('span',[_vm._v(" "+_vm._s(item.active_history.management_type.name)+" "),_c('br'),_vm._v(" "+_vm._s(item.active_history.management.name)+", "),_c('br'),(item.active_history.management.user)?_c('span',[_c('strong',[_vm._v(_vm._s(item.active_history.management.user.lastname)+" "+_vm._s(item.active_history.management.user.firstname))])]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(item.active_history.ministry_name)+" ")])]):_vm._e()]}},{key:"cell(deadline)",fn:function(ref){
var item = ref.item;
return [(item.active_history)?_c('span',{staticClass:"d-flex"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.active_history.deadline))+" ")]),_c('span',{staticClass:"ml-1"},[_c('b-icon',{attrs:{"icon":"calendar2-week"}})],1)]):_vm._e()]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.active_history)?_c('div',[_c('strong',[_vm._v(" "+_vm._s(item.active_history ? item.active_history.status.name : '')+" ")])]):_vm._e()]}},{key:"cell(author)",fn:function(ref){
var item = ref.item;
return [(item.active_history)?_c('span',[_vm._v(" "+_vm._s(item.active_history.author.firstname)+" "+_vm._s(item.active_history.author.lastname)+" ")]):_vm._e()]}},{key:"cell(updated_date)",fn:function(ref){
var item = ref.item;
return [(item.active_history)?_c('span',{staticClass:"d-flex"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.active_history.updated_at))+" ")]),_c('span',{staticClass:"ml-1"},[_c('b-icon',{attrs:{"icon":"calendar2-week"}})],1)]):_vm._e()]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.handlePageChange(data.item)}}},[_c('b-icon',{attrs:{"icon":"eye"}})],1),_c('b-button',{attrs:{"size":"sm","variant":"warning"},on:{"click":function($event){return _vm.handleEdit(data.item.id)}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1),_c('b-button',{staticClass:"ml-1",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.handleDelete(data.item.id)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1),(data.item.active_history.file_path)?_c('a',{staticClass:"ml-1",attrs:{"target":"_blank","href":("" + (_vm.backendPath.backendPath) + (data.item.active_history.file_path))}},[_c('b-button',{attrs:{"size":"sm","variant":"info"}},[_c('b-icon',{attrs:{"icon":"download"}})],1)],1):_vm._e()],1)]}}])})],1),_c('b-modal',{ref:"modal",attrs:{"id":"modal-prevent-closing","title":_vm.modalTitle,"hide-footer":"","size":"xl","scrollable":""},model:{value:(_vm.modalVisible),callback:function ($$v) {_vm.modalVisible=$$v},expression:"modalVisible"}},[_c('ParagraphForm',{attrs:{"id":_vm.id},on:{"saved":_vm.fetchList,"closeModal":_vm.closeModal}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }